<template>
  <div class="resource-components-wrapper">
    <div class="title">
      {{ $t('lightHealth') }}
    </div>
    <div class="section-wrapper">
      <div class="section-one">
        <div>
          <div>
            {{ $t('resourceView.one') }}
          </div>
          <div>
            <p>{{ $t('resourceView.two') }}</p>
            <p>{{ $t('resourceView.three') }}</p>
            <div></div>
          </div>
        </div>
      </div>
      <div class="section-two">
        <div class="section-two-section">
          <div>
            <img src="@/assets/image/resource/s2.jpg" alt="">
          </div>
          <div>
            <div>{{ $t('resourceView.four') }}</div>
            <div>{{ $t('resourceView.five') }}</div>
            <p style="height: 10px" v-if="this.$i18n.locale === 'en'" />
            <div>{{ $t('resourceView.six') }}</div>
          </div>
        </div>
        <div>
          {{ $t('resourceView.seven') }}
          <a href="https://www.nobelprize.org/prizes/medicine/2017/press-release/" target="_bank">https://www.nobelprize.org/prizes/medicine/2017/press-release/</a>
          )
        </div>
        <div class="section-two-section">
          <div class="right">
            <img src="@/assets/image/resource/s3.jpg" alt="">
          </div>
          <div class="left">
            <div>{{ $t('resourceView.eighth') }}</div>
            <div>{{ $t('resourceView.nine') }}</div>
            <p style="height: 10px" v-if="this.$i18n.locale === 'en'" />
            <div>{{ $t('resourceView.ten') }}</div>
          </div>
        </div>
        <div class="section-two-section">
          <div>
            <img src="@/assets/image/resource/s4.jpg" alt="">
          </div>
          <div>
            <p>
              {{ $t('resourceView.eleven') }}
              <a href="http://www.ox.ac.uk/research/research/research-in-conversation/healthy-body-healthy-mind/russell-foste" target="_bank">http://www.ox.ac.uk/research/research/research-in-conversation/healthy-body-healthy-mind/russell-foste</a>
              )
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-footer">
      <div>
        <img src="@/assets/image/resource/s5.jpg" alt="">
      </div>
      <div>
        {{ $t('resourceView.twelve') }}
      </div>
      <div>
        <p>
          {{ $t('resourceView.thirteen') }}
        </p>
        <p style="margin-bottom: 24px">
          {{ $t('resourceView.fourteen') }}
        </p>
        <p>
          {{ $t('resourceView.fifteen') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.resource-components-wrapper {
  width: 100%;
  margin: 0 auto;
  .title {
    width: 100%;
    line-height: 54px;
    text-align: center;
    color: rgba(34, 34, 34, 1);
    font-size: 42px;
    padding: 80px 0;
    font-weight: bold;
  }
  .section-wrapper {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    background: #fff;
    padding-bottom: 236px;
    .section-one {
      width: 100%;
      height: 530px;
      background: url('~@/assets/image/resource/s1.jpg') no-repeat center center;
      display: flex;
      align-items: center;
      &>div {
        min-width: 476px;
        background: #fff;
        margin-left: 77px;
        padding: 50px;
        &>div:nth-child(1) {
          width: 384px;
          color: #333333;
          line-height: 36px;
          font-size: 24px;
          font-weight: bold;
        }
        &>div:nth-child(2) {
          font-size: 18px;
          line-height: 25px;
          color: #333333;
          margin-top: 30px;
          &>div {
            width: 94px;
            height: 1px;
            margin-top: 70px;
            background: #000;
          }
        }
      }
    }
    .section-two {
      margin-top: 72px;
      width: 100%;
      &>div {
        &.section-two-section {
          display: flex;
          justify-content: space-between;
          height: 422px;
          width: 100%;
          align-items: center;
          &>div {
            &:nth-child(1) {
              width: 680px;
            }
            &:nth-child(2) {
              width: 435px;
              & > div {
                &:nth-child(1) {
                  font-size: 27px;
                  font-weight: bold;
                  color: #222222;
                  margin-bottom: 22px;
                }
                font-size: 18px;
                color: #333333;
                line-height: 32px;
              }
            }
            &.right {
              order: 2;
            }
            &.left {
              order: 1;
            }
          }
        }
        &:nth-child(2) {
          font-size: 18px;
          color: #333333;
          line-height: 32px;
          margin-top: 60px;
          a {
            color: rgba(17, 145, 218, 1);
          }
        }
        &:nth-child(3) {
          margin-top: 100px;
        }
        &:nth-child(4) {
          margin-top: 100px;
          p {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
            margin-bottom: 0;
            a {
              color: rgba(17, 145, 218, 1);
            }
          }
        }
      }
    }
  }
  .section-footer {
    width: 100%;
    background: #F5F6F7;
    min-height: 820px;
    &>div {
      &:nth-child(1) {
        width: 877px;
        height: 366px;
        margin: 0 auto;
        img {
          width: 100%;
          margin-top: -84px;
        }
      }
      &:nth-child(2) {
        width: 100%;
        text-align: center;
        font-size: 42px;
        font-weight: 600;
        color: #222222;
        line-height: 54px;
        margin-top: -15px;
      }
      &:nth-child(3) {
        padding-top: 40px;
        padding-bottom: 124px;
        max-width: 976px;
        margin: 0 auto;
        & > p {
          // text-align: center;
          font-size: 18px;
          color: #333333;
          line-height: 32px;
          &:nth-child(2) {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>